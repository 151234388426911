import { EnvironmentService } from '@/services/environment';
import {
  captureException,
  init,
  reactRouterV6BrowserTracingIntegration,
  wrapCreateBrowserRouterV6,
  type BrowserOptions,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

const options: BrowserOptions = {
  dsn: 'https://047d00f9653e4028ad75e591b7f9bdeb@o372675.ingest.sentry.io/5896639',
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
};

export const SentryService = {
  init: (config: BrowserOptions = {}) => {
    const isProd = process.env.NODE_ENV === 'production';
    const release = process.env.RELEASE_NAME;

    if (isProd && release) {
      EnvironmentService.getEnvironment().then((environment) => {
        if (environment === 'stage' || environment === 'production') {
          init({
            ...options,
            ...config,
            environment,
            release,
          });
        }
      });
    }
  },
  trackError: captureException,
  sentryCreateBrowserRouter: wrapCreateBrowserRouterV6(createBrowserRouter),
};
