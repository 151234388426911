import type { Permissions } from '@/domain/permission';
import type { PartyRoleType } from '@/domain/person';
import type { User } from '@/domain/user';
import { EnvironmentService } from '@/services/environment';
import { handleError, setItemInLocalStorageAndSendEvent } from '@/utils/utils';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { mutate } from 'swr';

const clearCache = () => mutate(() => true, undefined, { revalidate: false });

const redirectToAuthClient = () => {
  const { origin, pathname, search, hash } = window.location;

  const params = new URLSearchParams({
    // Base64-encoded to avoid conflicts with query params after redirect
    returnTo: btoa(`${pathname}${search}${hash}`),
    callbackUrl: `${origin}/auth/callback`,
  });
  const path = `/login?${params}`;

  EnvironmentService.getAuthClientUrl().then((authClientUrl) => {
    window.location.href = authClientUrl + path;
  });
};

export const logout = (): void => {
  clearCache();
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('permissions');
  redirectToAuthClient();
};

export function setAccessToken(token: string): void {
  setItemInLocalStorageAndSendEvent('access_token', token);
}

export function setRefreshToken(token: string): void {
  setItemInLocalStorageAndSendEvent('refresh_token', token);
}

export function setInviteToken(token: string): void {
  setItemInLocalStorageAndSendEvent('invite_token', token);
}

export const getAccessToken = () => localStorage.getItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const getInviteToken = () => localStorage.getItem('invite_token');

export const loggedIn = () => {
  const token = getRefreshToken();
  return !!token;
};

type ParsedJwt = {
  exp: number;
  organizationRoleTypes: PartyRoleType[];
  permissions: Permissions[];
  orgId: number;
  userId: number;
  loginId: number;
  trackId: number;
};

export const parseJwt = memoize(
  (token: string): ParsedJwt => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  },
  (token) => token,
);

export const getOrganizationId = memoize(
  () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return null;
    }
    const parsed = parseJwt(accessToken);

    return parsed?.orgId ?? null;
  },
  () => getAccessToken(),
);

export const getOrganizationRoles = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    const parsed = parseJwt(accessToken);

    return parsed?.organizationRoleTypes || [];
  }
  return [];
};

export const hasOrganizationPermissions = (organizationPermission: PartyRoleType[]) => {
  const organizationRoleTypes = getOrganizationRoles();
  return organizationPermission.some((organizationRoleType) =>
    organizationRoleTypes.includes(organizationRoleType),
  );
};

export const setUserPermissions = (permissions: Permissions[]) => {
  setItemInLocalStorageAndSendEvent('permissions', JSON.stringify(permissions));
};

export const getUserPermissions = memoize(
  () => {
    const value = localStorage.getItem('permissions') || '[]';
    const permissions: Permissions[] = JSON.parse(value);

    return permissions;
  },
  () => localStorage.getItem('permissions'),
);

export const getPermissionsFromUser = (user: User) => {
  const permissions = user.roles.map((role) => role.type.permissions).flat();
  return uniq(permissions).sort();
};

export const hasPermissions = (
  key: Permissions | Permissions[],
  permissions = getUserPermissions() ?? [],
) =>
  typeof key === 'string'
    ? !!permissions?.includes(key)
    : !!key?.every((permission) => permissions?.includes(permission));

export const isValidJwt = (jwt: string, jwtExpiryThreshold: number = 0) => {
  try {
    if (!jwt) {
      return false;
    }
    const parsedJwt = parseJwt(jwt);
    if (!parsedJwt) {
      return false;
    }
    return Date.now() < parsedJwt.exp * 1000 - jwtExpiryThreshold;
  } catch (error) {
    handleError(error, { displayToast: false });
    return false;
  }
};
